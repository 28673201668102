import { AccessesKeyEnum } from "..";

type KeyData = {
  description: string;
  title: string;
};

type GroupedAccessesData = {
  keys: Partial<Record<AccessesKeyEnum, KeyData>>;
  groupName: string;
  testId: string;
};

export type GroupedAccess = {
  group: string;
  key: {
    accessKey: AccessesKeyEnum;
    description?: string;
    title?: string;
  }[];
  groupName: string;
};

export type GroupedAccesses = Record<string, GroupedAccessesData>;

export const groupedAccesses: Record<
  string,
  {
    keys: Partial<
      Record<AccessesKeyEnum, { description: string; title: string }>
    >;
    groupName: string;
    testId: string;
  }
> = {
  mainDashboard: {
    keys: {
      "main_dashboard:all": {
        description: "Akses penuh dihalaman Beranda.",
        title: "",
      },
    },
    groupName: "Umum",
    testId: "__text__umum",
  },
  payment: {
    keys: {
      "payment:all": {
        description: "Akses penuh dihalaman Saldo Saya.",
        title: "",
      },
    },
    groupName: "Saldo",
    testId: "__text__saldo",
  },
  pos: {
    keys: {
      "pos:all": {
        description: "Akses penuh dihalaman Kasir.",
        title: "Kasir",
      },
    },
    groupName: "PoS",
    testId: "__text__pos",
  },
  order: {
    keys: {
      "order:view": {
        description: "Lihat informasi dihalaman Order.",
        title: "Order",
      },
      "order:action": {
        description: "Akses penuh dihalaman Order.",
        title: "Order",
      },
    },
    groupName: "Penjualan",
    testId: "__text__penjualan",
  },
  product: {
    keys: {
      "produk:view": {
        description: "Lihat informasi dihalaman Produk.",
        title: "Produk",
      },
      "produk:action": {
        description: "Akses penuh dihalaman Produk.",
        title: "Produk",
      },
    },
    groupName: "Produk",
    testId: "__text__produk",
  },
  stock: {
    keys: {
      "stock:view": {
        description: "Lihat informasi dihalaman Stok.",
        title: "Stok Inventori",
      },
      "stock:action": {
        description: "Akses penuh dihalaman Stok.",
        title: "Stok Inventori",
      },
      "virtual_bundling:view": {
        description: "Lihat informasi dihalaman Bundling Virtual.",
        title: "Virtual Bundling",
      },
      "virtual_bundling:action": {
        description: "Akses penuh dihalaman Bundling Virtual.",
        title: "Virtual Bundling",
      },
    },
    groupName: "Persediaan",
    testId: "__text__persediaan",
  },
  accountingJournal: {
    keys: {
      "accounting_journal:all": {
        description: "Akses penuh dihalaman Catatan Keuangan.",
        title: "Catatan Keuangan",
      },
    },
    groupName: "Keuangan",
    testId: "__text__keuangan",
  },
  report: {
    keys: {
      "reports_list:all": {
        description: "Akses penuh dihalaman Laporan.",
        title: "Laporan Toko",
      },
    },
    groupName: "Laporan",
    testId: "__text__laporan",
  },
  businessAnalysis: {
    keys: {
      "customer_analysis:all": {
        description: "Akses penuh dihalaman Analisis Pelanggan.",
        title: "Analisis Pelanggan",
      },
      "price_monitoring:all": {
        description: "Akses penuh dihalaman Pantau Kata Kunci.",
        title: "Pantau Kata Kunci",
      },
      "shop_monitoring:all": {
        description: "Akses penuh dihalaman Pantau Toko.",
        title: "Pantau Toko",
      },
    },
    groupName: "Analisis Bisnis",
    testId: "__text__analisis_bisnis",
  },
  catalogAndPromotion: {
    keys: {
      "catalog:all": {
        description: "Akses penuh dihalaman Katalog Melaka.",
        title: "Katalog Melaka",
      },
      "broadcast_campaign:all": {
        description: "Akses penuh dihalaman Broadcast via Whatsapp.",
        title: "Broadcast via Whatsapp",
      },
      "promotion:all": {
        description: "Akses penuh dihalaman Promosi Toko Online.",
        title: "Promosi Toko Online",
      },
    },
    groupName: "Katalog & Promosi",
    testId: "__text__katalog_and_promosi",
  },
  marketplaceConnection: {
    keys: {
      "marketplace_connection:view": {
        description: "Lihat informasi dihalaman Koneksi Toko Online.",
        title: "Koneksi Toko Online",
      },
      "marketplace_connection:action": {
        description: "Akses penuh dihalaman Koneksi Toko Online.",
        title: "Koneksi Toko Online",
      },
      "payment_method:all": {
        description: "Akses penuh dihalaman Biaya Layanan.",
        title: "Biaya Layanan",
      },
    },
    groupName: "Pengaturan",
    testId: "__text__pengaturan",
  },
  chat: {
    keys: {
      "chat:all": {
        description: "Akses penuh dihalaman Kelola Chat",
        title: "Kelola Chat",
      },
    },
    groupName: "Chat",
    testId: "__text__chat",
  },
  store: {
    keys: {
      "salesperson:all": {
        description: "Akses penuh dihalaman Tim Sales.",
        title: "Tim Sales",
      },
      "term_of_payment:all": {
        description: "Akses penuh dihalaman Piutang.",
        title: "Piutang",
      },
    },
    groupName: "Toko Saya",
    testId: "__text__toko_saya",
  },
  warehouse: {
    keys: {
      "warehouse:view": {
        description: "Lihat informasi dihalaman Gudang.",
        title: "Gudang",
      },
      "warehouse:action": {
        description: "Akses penuh dihalaman Gudang.",
        title: "Gudang",
      },
    },
    groupName: "Gudang",
    testId: "__text__gudang",
  },
  virtualBundling: {
    keys: {
      "virtual_bundling:view": {
        description: "Lihat informasi dihalaman Bundling Virtual.",
        title: "Virtual Bundling",
      },
      "virtual_bundling:action": {
        description: "Akses penuh dihalaman Bundling Virtual.",
        title: "Virtual Bundling",
      },
    },
    groupName: "Virtual Bundling",
    testId: "__text__virtual_bundling",
  },
  settings: {
    keys: {
      "settings_service_fee:all": {
        description: "Lihat informasi dihalaman Pengaturan.",
        title: "Biaya Layanan",
      },
    },
    groupName: "Pengaturan",
    testId: "__text__settings",
  },
};

export const accessList = [
  {
    category: "Umum",
    subcategories: [
      {
        subcategory: "Beranda",
        accesses: [
          {
            id: "main_dashboard:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Saldo",
    subcategories: [
      {
        subcategory: "Saldo Saya",
        accesses: [
          {
            id: "payment:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "PoS",
    subcategories: [
      {
        subcategory: "Kasir",
        accesses: [
          {
            id: "pos:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Penjualan",
    subcategories: [
      {
        subcategory: "Order",
        accesses: [
          {
            id: "order:view",
            description: "Lihat Informasi",
          },
          {
            id: "order:action",
            description: "Lakukan Aksi",
          },
        ],
      },
    ],
  },
  {
    category: "Produk",
    subcategories: [
      {
        subcategory: "Produk",
        accesses: [
          {
            id: "produk:view",
            description: "Lihat Informasi",
          },
          {
            id: "produk:action",
            description: "Lakukan Aksi",
          },
        ],
      },
    ],
  },
  {
    category: "Persediaan",
    subcategories: [
      {
        subcategory: "Stok Inventory",
        accesses: [
          {
            id: "stock:view",
            description: "Lihat Informasi",
          },
          {
            id: "stock:action",
            description: "Lakukan Aksi",
          },
        ],
      },
      {
        subcategory: "Virtual Bundling",
        accesses: [
          {
            id: "virtual_bundling:view",
            description: "Lihat Informasi",
          },
          {
            id: "virtual_bundling:action",
            description: "Lakukan Aksi",
          },
        ],
      },
    ],
  },
  {
    category: "Keuangan",
    subcategories: [
      {
        subcategory: "Catatan Keuangan",
        accesses: [
          {
            id: "accounting_journal:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Laporan",
    subcategories: [
      {
        subcategory: "Laporan Toko",
        accesses: [
          {
            id: "reports_list:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Analisis Bisnis",
    subcategories: [
      {
        subcategory: "Analisis Pelanggan",
        accesses: [
          {
            id: "customer_analysis:all",
            description: "Akses Penuh",
          },
        ],
      },
      {
        subcategory: "Pantau Kata Kunci",
        accesses: [
          {
            id: "price_monitoring:all",
            description: "Akses Penuh",
          },
        ],
      },
      {
        subcategory: "Pantau Toko",
        accesses: [
          {
            id: "shop_monitoring:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Katalog Promosi",
    subcategories: [
      {
        subcategory: "Promosi Katalog Melaka",
        accesses: [
          {
            id: "catalog:all",
            description: "Akses Penuh",
          },
        ],
      },
      {
        subcategory: "Broadcast Via Whatsapp",
        accesses: [
          {
            id: "broadcast_campaign:all",
            description: "Akses Penuh",
          },
        ],
      },
      {
        subcategory: "Promosi Toko Online",
        accesses: [
          {
            id: "promotion:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Pengaturan",
    subcategories: [
      {
        subcategory: "Koneksi Toko Online",
        accesses: [
          {
            id: "marketplace_connection:view",
            description: "Lihat Informasi",
          },
          {
            id: "marketplace_connection:action",
            description: "Lakukan Aksi",
          },
        ],
      },
      {
        subcategory: "Biaya Layanan",
        accesses: [
          {
            id: "payment_method:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Chat",
    subcategories: [
      {
        subcategory: "Kelola Chat",
        accesses: [
          {
            id: "chat:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Tim Sales",
    subcategories: [
      {
        subcategory: "Tim Sales",
        accesses: [
          {
            id: "salesperson:all",
            description: "Akses Penuh",
          },
        ],
      },
    ],
  },
  {
    category: "Gudang",
    subcategories: [
      {
        subcategory: "Gudang",
        accesses: [
          {
            id: "warehouse:view",
            description: "Lihat Informasi",
          },
          {
            id: "warehouse:action",
            description: "Lakukan Aksi",
          },
        ],
      },
    ],
  },
  {
    category: "Virtual Bundling",
    subcategories: [
      {
        subcategory: "Virtual Bundling",
        accesses: [
          {
            id: "virtual_bundling:view",
            description: "Lihat Informasi",
          },
          {
            id: "virtual_bundling:action",
            description: "Lakukan Aksi",
          },
        ],
      },
    ],
  },
];

export const navigationAccesses = [
  "main_dashboard:all",
  "payment:all",
  "salesperson:all",
  "pos:all",
  "chat:all",
  "catalog:all",
  "term_of_payment:all",
  "order:view",
  "order:action",
  "product_collection:view",
  "product_collection:action",
  "produk:view",
  "produk:action",
  "stock:view",
  "stock:action",
  "accounting_journal:all",
  "reports_list:all",
  "customer_analysis:all",
  "price_monitoring:all",
  "shop_monitoring:all",
  "loyalty_points:action",
  "loyalty_points:view",
  "promotion:all",
  "broadcast_campaign:all",
  "marketplace_connection:view",
  "marketplace_connection:action",
  "payment_method:all",
  "virtual_bundling:view",
  "virtual_bundling:action",
  "warehouse:action",
  "warehouse:view",
];

export const templateRolesOptions = [
  {
    label: "Tidak pakai template",
    value: "none",
    testid: "__tidak-pakai-template",
    accesses: [],
  },
  {
    label: "Manager",
    value: "manager",
    testid: "__manager",
    accesses: [
      "main_dashboard:all",
      "payment:all",
      "pos:all",
      "order:view",
      "order:action",
      "produk:view",
      "produk:action",
      "stock:view",
      "stock:action",
      "accounting_journal:all",
      "reports_list:all",
      "customer_analysis:all",
      "price_monitoring:all",
      "catalog:all",
      "broadcast_campaign:all",
      "promotion:all",
      "product_collection:view",
      "product_collection:action",
      "marketplace_connection:view",
      "marketplace_connection:action",
      "chat:all",
      "shop_monitoring:all",
      "term_of_payment:all",
      "salesperson:all",
      "loyalty_points:action",
      "loyalty_points:view",
      "payment_method:all",
      "virtual_bundling:view",
      "virtual_bundling:action",
      "warehouse:view",
      "warehouse:action",
    ],
  },
  {
    label: "Sales",
    value: "sales",
    testid: "__sales",
    accesses: [
      "main_dashboard:all",
      "pos:all",
      "catalog:all",
      "customer_analysis:all",
      "price_monitoring:all",
      "reports_list:all",
      "shop_monitoring:all",
    ],
  },
  {
    label: "Warehouse",
    value: "warehouse",
    testid: "__warehouse",
    accesses: [
      "main_dashboard:all",
      "order:view",
      "order:action",
      "produk:view",
      "produk:action",
      "stock:view",
      "stock:action",
      "reports_list:all",
    ],
  },
  {
    label: "Marketing",
    value: "marketing",
    testid: "__marketing",
    accesses: [
      "catalog:all",
      "broadcast_campaign:all",
      "promotion:all",
      "reports_list:all",
      "customer_analysis:all",
      "price_monitoring:all",
      "shop_monitoring:all",
    ],
  },
  {
    label: "Finance",
    value: "finance",
    testid: "__finance",
    accesses: [
      "main_dashboard:all",
      "payment:all",
      "produk:view",
      "produk:action",
      "stock:view",
      "stock:action",
      "accounting_journal:all",
      "reports_list:all",
    ],
  },
  {
    label: "Cashier",
    value: "cashier",
    testid: "__cashier",
    accesses: ["pos:all", "order:view", "order:action"],
  },
  {
    label: "Customer Service",
    value: "customer_service",
    testid: "__customer-service",
    accesses: ["order:view", "chat:all"],
  },
];
