import { navigationAccesses } from "..";

export const sortRoute = (accesses: string[]) => {
  const accessIndexMap = navigationAccesses.reduce((prev, curr, index) => {
    return {
      ...prev,
      [curr]: index,
    };
  }, {} as Record<string, number>);

  const orderedAccesses = accesses.sort((a, b) => {
    const aIndex = accessIndexMap[a];
    const bIndex = accessIndexMap[b];

    if (aIndex === undefined && bIndex === undefined) {
      return 0; // Both are undefined, maintain relative order
    }

    if (aIndex === undefined) {
      return 1; // A should come after B
    }

    if (bIndex === undefined) {
      return -1; // B should come after A
    }

    // Both are defined, sort normally
    return aIndex - bIndex;
  });

  return orderedAccesses;
};

export default sortRoute;
